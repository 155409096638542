export enum ConfigManagerProperties {
  buildVersion = 'buildVersion',
  production = 'production',
  apiUrl = 'apiUrl',
  scopeOptions = 'scopeOptions',
  secretToken = 'secretToken',
  appName = 'appName',
  appNames = 'appNames',
  appConfig = 'appConfig',
  region = 'region',
  loggedInUserRoot = 'loggedInUserRoot',
}
