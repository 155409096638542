<xpo-shell [drawerClosedByDefault]="true">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>ACOR</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <button routerLink="acor" routerLinkActive="xpo-selected">ACOR</button>
  </xpo-header-navigation>
  <xpo-header-actions>
    <xpo-auth-user-profile></xpo-auth-user-profile>
  </xpo-header-actions>
  <div class="container">
    <div class="main">
      <router-outlet></router-outlet>
    </div>
    <xpo-footer class="footer" xpoFooterSticky [version]="build"></xpo-footer>
  </div>
</xpo-shell>
<xpo-app-notification></xpo-app-notification>
