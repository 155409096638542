import { Component } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from './shared/config-manager-properties.enum';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { UserService } from './shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  build: string;

  constructor(
    private configManagerService: ConfigManagerService,
    private userService: UserService,
    private authService: XpoLtlAuthenticationService
  ) {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe();
    this.userService.getLoggedInUser$().subscribe();

    const prod = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    if (prod) {
      this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    } else {
      this.build = `${this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion)} - ${region}`;
    }
  }
}
