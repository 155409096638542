import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AcorVoidPayload,
  BillCorrectionApiService,
  StartAcorVoidChEnsembleQuery,
  StartAcorVoidChEnsembleRqst,
} from '@xpo-ltl-2.0/sdk-billcorrection';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { ProNumberValidator } from '../../shared/validators/pro-number.validator';
import { UserService } from '../../shared/services/user.service';
import { map } from 'rxjs/operators';
import { TypeCode } from '../../shared/models/typeCodes';
import { TypeCodeName } from '../../shared/enums/type-codes.enum';
import { CustomSnackbarComponent } from '../../shared/components/custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-acor-card',
  templateUrl: './acor-card.component.html',
  styleUrls: ['./acor-card.component.scss'],
})
export class AcorCardComponent {
  acorReplacementForm: FormGroup;

  typeCodes: TypeCode[] = [];

  constructor(
    private fb: FormBuilder,
    private snackbar: XpoSnackBar,
    private userService: UserService,
    private billCorrectionService: BillCorrectionApiService
  ) {
    this.acorReplacementForm = this.fb.group({
      proNumber: ['', [Validators.required, ProNumberValidator]],
      acorTypeCd: ['', [Validators.required]],
    });
    this.prepareTypeCodes();
  }

  private prepareTypeCodes(): void {
    this.userService
      .getLoggedInUser$()
      .pipe(map((user) => this.userService.isAdmin(user)))
      .subscribe((isAdmin) => {
        if (isAdmin) {
          this.typeCodes.push({ name: TypeCodeName.ACOR_RESET, value: 'acor-reset' });
          this.typeCodes.push({ name: TypeCodeName.ACOR_PURGE, value: 'acor-purge' });
          this.typeCodes.push({ name: TypeCodeName.ACOR_ZERO, value: 'acor-zero' });
          this.typeCodes.push({ name: TypeCodeName.VOID, value: 'void' });
        }
      });
  }

  onSubmit() {
    if (this.acorReplacementForm.valid) {
      const proNumber = this.acorReplacementForm.get('proNumber')?.value.trim();
      const acorTypeCd = this.acorReplacementForm.get('acorTypeCd')?.value;

      const request = new StartAcorVoidChEnsembleRqst();
      request.ensembleName = acorTypeCd;
      request.businessKey1 = proNumber;
      request.payload = new AcorVoidPayload();
      request.payload.proNbr = proNumber;

      const queryParams = new StartAcorVoidChEnsembleQuery();
      queryParams.synchronous = true;

      this.billCorrectionService.startAcorVoidChEnsemble(request, queryParams).subscribe(
        (resp: any) => {
          const response = resp.response?.validationErrors;
          if (response && response.length) {
            const error = response[0];
            this.snackbar.open({
              message: 'ACOR Request Failed:',
              detailedMessageComponent: CustomSnackbarComponent,
              detailedMessageComponentData: {
                title: `[${error.errorCd}] - ${error.message}`,
                moreInfo: error.moreInfo,
              },
              status: 'error',
              matConfig: {
                duration: 10000,
              },
            });
          } else {
            this.snackbar.success('ACOR Request Submitted Successfully.');
          }
        },
        (error) => {
          this.snackbar.error('ACOR Request Failed.');
          console.error(error);
        }
      );
    }
  }
}
