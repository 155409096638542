import { AbstractControl } from '@angular/forms';
import { XpoLtlValueValidationService } from './value-validation.service';

export function ProNumberValidator(control: AbstractControl) {
  const value = control.value.trim();
  const service = new XpoLtlValueValidationService();

  if (service.isValidProNumber(value)) {
    return null;
  }

  return { proNumberError: true };
}
