import { Injectable } from '@angular/core';
import { partialRight as _partialRight } from 'lodash';
import { XpoLtlValidationRegex } from './validation-regex.enum';

@Injectable({ providedIn: 'root' })
export class XpoLtlValueValidationService {
  constructor() {}

  isValidProNumber(inputValue: string): boolean {
    if (!new RegExp(XpoLtlValidationRegex.validProCharacterCheck).test(inputValue)) {
      return false;
    }

    const proNumber: string = this.conditionProNumber(inputValue);
    if (proNumber === '' || proNumber.length < 9 || proNumber.length > 11) {
      return false;
    }
    // divide first 8 chars by 7 multiple remainder by 7
    const checkDigit = Number(proNumber.substring(8));
    const number = Number.parseInt(proNumber.substring(0, 8));
    const mod = number % 7;
    return mod === checkDigit;
  }

  conditionProNumber(proNumber: string, digits?: any): string {
    if (!proNumber) {
      return '';
    }
    proNumber = this.clean(proNumber);
    proNumber = this.convert(proNumber, digits);
    return proNumber;
  }

  private clean(proNumber: string): string {
    return proNumber.replace(/\D/g, '');
  }

  private convert(proNumber: string = '', digits: any): string {
    let part1, part2, trim, trimmed;
    trim = _partialRight(this.trim.bind(this), '0', 'g', digits);
    trimmed = trim(proNumber);

    if (!digits) {
      digits = 9;
    }
    if (trimmed.length < 9) {
      // if it had a leading 0 as part of the real number
      if (trimmed.length < 8) {
        trimmed = `00${trimmed}`;
      } else {
        trimmed = `0${trimmed}`;
      }
    }

    let childPart1: string = '';
    if (trimmed.length >= 10) {
      childPart1 = trimmed.slice(0, 4);
      if (parseInt(childPart1.charAt(3)) > 0) {
        digits = 'child';
      }
    }

    if (childPart1 && digits === 'child') {
      part1 = childPart1;
      part2 = trimmed.slice(4);
      part2 = part2.length > 6 ? trim(part2) : part2;

      return `${part1}-${part2}`;
    } else {
      part1 = trimmed.slice(0, 3);
      part2 = trimmed.slice(3);
      part2 = part2.length > 6 ? trim(part2) : part2;

      let retValue = '';
      if (digits === 11) {
        retValue = `0${part1}0${part2}`;
      } else if (digits === 10) {
        retValue = `${part1}-${part2}`;
      } else {
        retValue = part1.concat(part2);
      }

      return retValue;
    }
  }

  private trim(string = '', what = ',', flags = 'g'): string {
    let escaped, find;
    escaped = what.replace(/[\[\](){}?*+\^$\\.|\-]/g, '\\$&');
    find = new RegExp('^[' + escaped + ']', flags);

    return string.replace(find, '');
  }
}
