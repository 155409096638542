import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AcorReplacementComponent } from './acor-replacement/acor-replacement.component';

const routes: Routes = [
  {
    path: 'acor',
    component: AcorReplacementComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  { path: '', redirectTo: 'acor', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
