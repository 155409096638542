import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, delay, filter, retryWhen, take, tap, map } from 'rxjs/operators';
import { ConfigManagerProperties } from '../config-manager-properties.enum';
import { User } from '@xpo-ltl/sdk-common';
import { BillCorrectionApiService } from '@xpo-ltl-2.0/sdk-billcorrection';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly isProduction;
  private readonly adminRole;
  private userSubject = new BehaviorSubject<User>(new User());
  private loggedInSubject = new BehaviorSubject<boolean>(false);

  get user(): User {
    return this.userSubject.value;
  }

  set user(u: User) {
    this.userSubject.next(u);
  }

  constructor(
    private configManagerService: ConfigManagerService,
    private billCorrectionApiService: BillCorrectionApiService
  ) {
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    this.adminRole = this.isProduction ? 'ACOR_Admin' : 'TST_ACOR_Admin';
  }

  isAdmin(user: User): boolean {
    const roles = user.roles.map((role) => role.substring(role.lastIndexOf('/') + 1));
    return roles.includes(this.adminRole);
  }

  getLoggedInUser$(): Observable<User> {
    return !!this.user.displayName ? of(this.user) : this.getLoggedInUser();
  }

  onLogoutUser() {
    this.loggedInSubject.complete();
    this.user = new User();
  }

  private getLoggedInUser(): Observable<User> {
    return this.billCorrectionApiService.loggedInUser({ loadingOverlayEnabled: true }).pipe(
      retryWhen((errors) => errors.pipe(delay(1000), take(5))),
      catchError((error) => this.handleUserError(error, true)),
      map((user) => {
        return { ...user } as User;
      })
    );
  }

  private handleUserError(error: any, resetUser = false) {
    console.error(error);

    if (resetUser) {
      this.userSubject.next(new User());
      this.loggedInSubject.complete();
    }

    return throwError(error);
  }
}
