import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { XpoFormFieldModule } from '@xpo-ltl/ngx-ltl-core/form-field';
import { AcorReplacementComponent } from './acor-replacement/acor-replacement.component';
import { SdkBillCorrectionModule } from '@xpo-ltl-2.0/sdk-billcorrection';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoSelectModule } from '@xpo-ltl/ngx-ltl-core/select';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { MatOptionModule } from '@angular/material/core';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { DataApiModule } from '@xpo-ltl/data-api';
import { AcorCardComponent } from './acor-replacement/acor-card/acor-card.component';
import { SharedModule } from './shared/shared.module';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, AcorReplacementComponent, AcorCardComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoLtlAuthUiModule,
    ConfigManagerModule,
    XpoFormFieldModule,
    SdkBillCorrectionModule,
    XpoShellModule,
    XpoFooterModule,
    XpoSelectModule,
    XpoButtonModule,
    XpoCardModule,
    XpoIconModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    XpoSnackBarModule,
    DataApiModule,
    AppRoutingModule,
    SharedModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
