import { NgModule } from "@angular/core";
import { UserService } from "./services/user.service";
import { CustomSnackbarComponent } from "./components/custom-snackbar/custom-snackbar.component";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [CustomSnackbarComponent],
  imports: [CommonModule],
  providers: [UserService],
  exports: [CustomSnackbarComponent],
})
export class SharedModule {}
