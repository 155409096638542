import { Component } from '@angular/core';

@Component({
  selector: 'app-acor-replacement',
  templateUrl: './acor-replacement.component.html',
  styleUrls: ['./acor-replacement.component.scss'],
})
export class AcorReplacementComponent {

  constructor() {}
}
