<xpo-card>
  <xpo-card-content>
    <form [formGroup]="acorReplacementForm" class="form">
      <mat-form-field class="xpo-Card-content-form-select-field" floatLabel="always">
        <mat-label>PRO Number</mat-label>
        <input
          matInput
          formControlName="proNumber"
          autocomplete="off"
          autofocus
          placeholder="Enter PRO Number"
          required
        />
        <mat-error class="columns" *ngIf="acorReplacementForm.get('proNumber')?.errors">
          <span
            *ngIf="
              acorReplacementForm.get('proNumber')?.hasError('required') &&
              acorReplacementForm.get('proNumber')?.touched
            "
          >
            This field is required
          </span>
          <span
            *ngIf="
              acorReplacementForm.get('proNumber')?.hasError('proNumberError') &&
              acorReplacementForm.get('proNumber')?.touched &&
              !acorReplacementForm.get('proNumber')?.hasError('required')
            "
          >
            Invalid PRO Number
          </span>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="xpo-Card-content-form-select-field" floatLabel="always">
        <mat-label>Type Code</mat-label>
        <mat-select placeholder="Select ACOR" xpoSelect formControlName="acorTypeCd" required>
          <mat-option *ngFor="let typeCode of typeCodes" [value]="typeCode.value">
            {{ typeCode.name }}
          </mat-option>
        </mat-select>
        <mat-error class="columns" *ngIf="acorReplacementForm.get('acorTypeCd')?.errors">
          <span
            *ngIf="
              acorReplacementForm.get('acorTypeCd')?.hasError('required') &&
              acorReplacementForm.get('acorTypeCd')?.touched
            "
          >
            This field is required
          </span>
        </mat-error>
      </mat-form-field>
    </form>
  </xpo-card-content>
  <xpo-card-footer>
    <button
      class="acor-form-button"
      mat-flat-button
      xpoButton
      [disabled]="!acorReplacementForm.valid"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </xpo-card-footer>
</xpo-card>
