import { Component, Inject } from '@angular/core';
import { XPO_SNACKBAR_DETAILED_MESSAGE_DATA } from '@xpo-ltl/ngx-ltl-core/snack-bar';

@Component({
  selector: 'custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
})
export class CustomSnackbarComponent {

  title = '';
  moreInfo = [];

  constructor(@Inject(XPO_SNACKBAR_DETAILED_MESSAGE_DATA) public data: any) {
    this.title = data.title;
    this.moreInfo = this.data.moreInfo.map((item: any) => this.parseMessage(item.location, item.message)).filter((item: any) => !!item);
  }

  private parseMessage(location: string, message: string): string {
    if (location.toLowerCase().includes('trace')) {
      return '';
    }

    return `[${location}] - ${message}`;
  }
}
